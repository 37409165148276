<template>
  <div>
    <!-- Error404 v-if="error404occured" / -->
    <div class="api">
      <div class="container space-lg-1 pl-0">
        <div aria-header="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link :to="{ path: `/${$route.params.lang}/`}">{{ $t('Header.Home') }}</router-link>
            </li>
            <li class="breadcrumb-item" aria-current="page">
              <router-link :to="{ path: `/${$route.params.lang}/use-cases`}" class="breadcrumb-item">{{ $t('UseCases.Overview.Title') }}</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page"><a class="disabled">{{ $t(useCaseInfo.categoryName) }}</a></li>
          </ol>
        </div>
      </div>
      <div id="scroll-to-div">
        <div id="stickyBlockStartPoint" class="col-lg-2 pr-xl-5 mb-5 mb-lg-0" />
        <!-- Banner Section -->
        <div class="container">
          <div class="card align-items-end flex-wrap flex-row bg-img-hero text-white h-100 min-h-270rem" :style="{ backgroundImage: `url(${require(`@/assets/svg/illustrations/use-cases/${useCaseInfo.bannerImage}.svg`)})`}">
            <div class="card-body pl-5 pl-lg-7 pb-5">
              <span class="d-block text-white font-weight-bold text-cap mb-2 text-shadow">{{ $t('UseCases.Overview.Industry') }}</span>
              <h1 class="text-white text-shadow">{{ $t(useCaseInfo.categoryName) }}</h1>
            </div>
          </div>
        </div>
        <!-- End Banner Section -->
        <!-- Title -->
        <div class="container text-center pt-10 pb-5">
          <h2>{{ $t('UseCases.DetailPageTitle') }} {{ $t(useCaseInfo.categoryName) }} APIs</h2>
          <p class="lead">{{ $t('UseCases.DetailPageDescription') }}</p>
        </div>
        <!-- End Title -->
        <!-- Use Case Section -->
        <div class="container">
          <div v-for="(inspiration, index) in useCaseInfo.inspirations" :key="index" class="row align-items-lg-center mb-5 mb-lg-0">
            <div class="col-lg-6" :class="index % 2 === 1 ? 'order-lg-1' : 'order-lg-0'">
              <h3 class="mb-3">{{ $t(inspiration.title) }}</h3>
              <div class="media mb-3">
                <span class="icon icon-xs icon-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-dark">{{ $t(inspiration.description) }}</div>
              </div>
            </div>
            <div class="col-lg-6" :class="index % 2 === 1 ? 'order-lg-0' : 'order-lg-1'">
              <div class="w-60 w-lg-80 mx-auto mx-sm-auto mx-md-auto" :class="index % 2 === 1 ? 'mr-lg-auto ml-lg-0' : 'ml-lg-auto mr-lg-0'">
                <img class="img-fluid" :src="require(`@/assets/svg/illustrations/use-cases/${inspiration.image}.svg`)">
              </div>
            </div>
          </div>
        </div>
        <!-- End Use Case Section -->
        <!-- CTA Section -->
        <Register />
        <!-- End CTA Section -->
        <div id="stickyBlockEndPoint" />
      </div>
    </div>
  </div>
</template>

<script>
const $ = require('jquery')
import Register from '@/components/register'
import useCasesCategories from './useCasesCategories.json'

export default {
  name: 'UseCasesDetails',
  components: {
    Register
  },
  data() {
    return {
      use_case_id: '',
      useCaseInfo: {},
      useCasesCategories: useCasesCategories,
      featured_api: [],
      error404occured: false
    }
  },
  watch: {
    $route(to, from) {
      this.use_case_id = this.$route.params && this.$route.params.id
      this.error404occured = false
      this.getUseCaseInfo()
    }
  },
  created() {
    this.use_case_id = this.$route.params && this.$route.params.id
    this.getUseCaseInfo()
  },
  methods: {
    getUseCaseInfo() {
      const id = this.use_case_id
      const filteredUseCase = useCasesCategories.Categories.filter(function(category) {
        return category.id === id
      })
      if (filteredUseCase.length > 0) {
        this.useCaseInfo = filteredUseCase[0]
      } else {
        this.error404occured = true
      }
    },
    showRegisterForm() {
      $('#registerForm').modal('show')
    }
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: `API Use Cases | %s`,
      link: [
        { rel: 'canonical', href: `https://www.openapihub.com/use-cases/${this.useCaseInfo.id}` }
      ],
      meta: [
        { name: 'description', content: `Explore our API Use Cases and find inspiration for innovative solutions with OpenAPIHub.` },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: `API Use Cases | OpenAPIHub` },
        { property: 'og:description', content: `Explore our API Use Cases and find inspiration for innovative solutions with OpenAPIHub.` },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: `https://www.openapihub.com/use-cases/${this.useCaseInfo.id}` }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.step-text {
  text-align: justify;
  text-align-last: left;
}
</style>
