<template>
  <div>
    <div class="position-relative bg-img-hero">
      <div id="registerSection" class="container space-2 space-bottom-lg-3">
        <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
          <!-- <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('JoinUsSection.Subtitle') }}</span> -->
          <h2>{{ $t('JoinUsSection.Title') }}</h2>
          <p>{{ $t('JoinUsSection.Description') }}</p>
        </div>

        <div class="w-md-65 mx-auto">
          <div class="row justify-content-center">
            <div v-for="(card, index) in registerCards" :key="index" class="col-12 col-md-6 mb-3 mb-lg-0">
              <div class="card card-bg-light h-100 shadow-soft overflow-hidden transition-3d-hover">
                <div class="row align-items-center no-gutters">
                  <div class="col-8 col-md-12 col-lg-6">
                    <div class="py-3 pl-4">
                      <h4 class="d-none d-lg-block">{{ $t(card.title) }}</h4>
                      <h5 class="d-lg-none">{{ $t(card.title) }}</h5>
                      <a v-if="card.link" :id="`${card.id}`" :href="`${card.link}`" class="font-size-1 font-weight-bold" target="_blank">{{ $t(card.button) }} <i class="fas fa-angle-right fa-sm ml-1" /></a>
                      <a v-if="card.form" :id="`${card.id}`" href="javascript:;" data-toggle="modal" :data-target="`#${card.form}`" class="font-size-1 font-weight-bold">{{ $t(card.button) }} <i class="fas fa-angle-right fa-sm ml-1" /></a>
                    </div>
                  </div>
                  <div class="col-4 col-md-12 col-lg-6 px-0">
                    <img class="img-fluid" :src="require(`@/assets/svg/illustrations/${card.img}.svg`)" alt="SVG">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <PartnerEcoSystemForm />
    <!-- <ConsumerForm /> -->
  </div>
</template>

<script>
/* eslint-disable */
const $ = require('jquery')
import 'bootstrap'
import PartnerEcoSystemForm from '@/components/partner-ecosystem-form'
// import ConsumerForm from '@/components/consumer-form'

export default {
  name: 'Register',
  components: {
    PartnerEcoSystemForm,
    // ConsumerForm
  },
  data() {
    return {
      registerCards: [
        // {
        //     title: "JoinUsSection.APIProviders",
        //     img: "provider-apply",
        //     button: "JoinUsSection.RegisterFree",
        //     id: "ga-OW01-07-04",
        //     link: "https://hub.openapihub.com/get-started"
        // },
        {
            title: "JoinUsSection.APIConsumers",
            img: "consumer-apply",
            button: "JoinUsSection.RegisterFree",
            id: "ga-OW01-07-05",
            link: "https://hub.openapihub.com/"
        }
        // {
        //     title: "JoinUsSection.CommunityPartners",
        //     img: "community-apply",
        //     button: "JoinUsSection.RegisterNow",
        //     id: "OW01-07-06",
        //     form: "partnerEcoSystemForm"
        // }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
